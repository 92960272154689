import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = localStorage.getItem('authToken');
    const roleId = localStorage.getItem('roleId');

    let headers = req.headers;

    if (authToken) {
      headers = headers.set('Authorization', `Bearer ${authToken}`);
    }

    if (roleId) {
      headers = headers.set('RoleId', roleId);
    }

    const authReq = req.clone({ headers });

    return next.handle(authReq).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const newAuthToken = event.headers.get('Authorization');
          const newRoleId = event.headers.get('RoleId');
          if (newAuthToken) {
            localStorage.setItem('authToken', newAuthToken);
            console.log('New auth token stored in localStorage:', localStorage.getItem('authToken'));
          }
          if (newRoleId) {
            localStorage.setItem('roleId', newRoleId);
            console.log('New role id stored in localStorage:', localStorage.getItem('roleId'));
          }
        }
      })
    );
  }
}